import {
  Anchor,
  Button,
  Checkbox,
  Group,
  Input,
  Modal,
  Text,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { Notification, UserInfo } from "@prisma/client";
import { User } from "@supabase/supabase-js";
import { getCookie, setCookie } from "cookies-next";
import { atom, useAtom } from "jotai";
import Link from "next/link";
import Router from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useSignIn } from "react-supabase";
import { z } from "zod";
import { supabase } from "../utils/supabase";

const formSchema = z.object({
  email: z.string().email({ message: "Adresse email incorrecte" }),
  password: z.string().min(2, { message: "Veuillez rentrer un mot de passe" }),
});

export const userAtom = atom<User | null>(null);
export const userInfoAtom = atom<UserInfo | null>(null);
export const notificationsAtom = atom<Notification[] | null>(null);
export const modalConnectionAtom = atom(false);

export default function UserContext({
  children,
}: {
  children: React.ReactNode;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [{ fetching }, signIn] = useSignIn();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: zodResolver(formSchema),
  });

  async function signInWithEmail() {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) return alert(error);
    if (fetching) return <div>Signing in</div>;
    if (data) Router.push({ pathname: "./events" });
  }

  const [, setUser] = useAtom(userAtom);
  const [, setUserInfo] = useAtom(userInfoAtom);
  const [, setNotifications] = useAtom(notificationsAtom);
  const [modalConnection, setModalConnection] = useAtom(modalConnectionAtom);

  const getUser = async () => {
    const { data, error } = await supabase.auth.getSession();

    if (data.session?.user) {
      setUser(data.session.user);
      setCookie("uid", data.session.user.id);
    }
  };

  const getUserInfo = async () => {
    if (user) {
      let { data, error } = await supabase
        .from("UserInfo")
        .select("*")
        .eq("uid", user.id);
      setUserInfo(data?.at(0));
    }
  };

  const getNotifications = async () => {
    let { data, error } = await supabase
      .from("Notification")
      .select("*")
      .eq("userId", getCookie("uid"));

    setNotifications(data);
  };

  const [user] = useAtom(userAtom);
  const [userInfo] = useAtom(userInfoAtom);

  useMemo(() => {
    getUser();
  }, []);

  useMemo(() => {
    getUserInfo();
  }, [user]);

  useMemo(() => {
    getNotifications();
  }, [setNotifications]);

  return (
    <>
      <Modal
        opened={modalConnection}
        onClose={() => setModalConnection(false)}
        title="Compte nécessaire !"
        radius={"sm"}
        centered
      >
        <>
          <form onSubmit={form.onSubmit((values) => console.log(values))}>
            <Input.Wrapper
              id="email"
              label="Adresse email"
              required
              {...form.getInputProps("email")}
            >
              <Input
                placeholder="Email"
                value={form.values.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.currentTarget.value);
                  form.setFieldValue("email", event.currentTarget.value);
                }}
              />
            </Input.Wrapper>
            <Input.Wrapper
              id="password"
              label="Mot de passe"
              required
              {...form.getInputProps("password")}
            >
              <Input
                placeholder="Mot de passe"
                value={form.values.password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.currentTarget.value);
                  form.setFieldValue("password", event.currentTarget.value);
                }}
              />
            </Input.Wrapper>
          </form>
          <Group position="apart" mt="md">
            <Checkbox label="Se souvenir de moi" />
            <Anchor<"a">
              onClick={(event) => event.preventDefault()}
              href="#"
              size="sm"
            >
              Mot de passe oublié ?
            </Anchor>
          </Group>

          <Text color="dimmed" size="sm" align="left" mt={10}>
            Vous n&apos;avez pas encore de compte ?{" "}
            <Link href={"/onboarding"} passHref>
              <Anchor
                component="a"
                size="sm"
                onClick={() => setModalConnection(false)}
              >
                Créer un compte
              </Anchor>
            </Link>
          </Text>

          <Button
            fullWidth
            mt="xl"
            onClick={() => signInWithEmail()}
            radius="md"
          >
            Se connecter
          </Button>
        </>
      </Modal>
      {children}
    </>
  );
}

import { createContext, useState } from "react";

interface ThemeValue {
  fontType: string;
  navbarFontSize: string;
  userlinkFontSize: string;
  artistIcon: string;
  hostIcon: string;
  headerColor: string;
  mainColor: string;
  secondaryColor: string;
  thirdColor: string;
}

interface ThemeContextType {
  themeValues: { [theme: string]: ThemeValue };
}

export const SpecificThemeContext = createContext<ThemeContextType>({
  themeValues: {},
});

export default function SpecificThemeCProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [themeValues, setThemeValues] = useState<{
    [theme: string]: ThemeValue;
  }>({
    classic: {
      fontType: "font-classic",
      navbarFontSize: "text-3xl",
      userlinkFontSize: "text-4xl",
      artistIcon: "/artist-icons/classic.png",
      hostIcon: "/host-icons/classic.png",
      headerColor: "#881337",
      mainColor: "#1B033F",
      secondaryColor: "#213C62",
      thirdColor: "#335376",
    },
    disco: {
      fontType: "font-disco",
      navbarFontSize: "text-2xl",
      userlinkFontSize: "text-3xl",
      artistIcon: "/artist-icons/disco.png",
      hostIcon: "/host-icons/disco.png",
      headerColor: "#0A4C8F",
      mainColor: "#7267A1",
      secondaryColor: "#514489",
      thirdColor: "#AC6AB9",
    },
    edm: {
      fontType: "font-edm",
      navbarFontSize: "text-[1.3rem]",
      userlinkFontSize: "text-[1.6rem]",
      artistIcon: "/artist-icons/edm.png",
      hostIcon: "/host-icons/edm.png",
      headerColor: "#030609",
      mainColor: "#25184e",
      secondaryColor: "#4D4187",
      thirdColor: "#7164BB",
    },
    jazz: {
      fontType: "font-jazz",
      navbarFontSize: "text-[1.4rem]",
      userlinkFontSize: "text-[1.8rem]",
      artistIcon: "/artist-icons/jazz.png",
      hostIcon: "/host-icons/jazz.png",
      headerColor: "#6c4613",
      mainColor: "#0c1e1d",
      secondaryColor: "#48282A",
      thirdColor: "#704F4F",
    },
    rap: {
      fontType: "font-rap",
      navbarFontSize: "text-2xl",
      userlinkFontSize: "text-3xl",
      artistIcon: "/artist-icons/rap.png",
      hostIcon: "/host-icons/rap.png",
      headerColor: "#AB9969",
      mainColor: "#262626",
      secondaryColor: "#575757",
      thirdColor: "#858585",
    },
    rock: {
      fontType: "font-rock",
      navbarFontSize: "text-[1.1rem]",
      userlinkFontSize: "text-[1.3rem]",
      artistIcon: "/artist-icons/rock.png",
      hostIcon: "/host-icons/rock.png",
      headerColor: "#0C0B0B",
      mainColor: "#2E2E5066",
      secondaryColor: "#484d48",
      thirdColor: "#636863",
    },
  });

  const value = {
    themeValues,
  };

  return (
    <>
      <SpecificThemeContext.Provider value={value}>
        {children}
      </SpecificThemeContext.Provider>
    </>
  );
}

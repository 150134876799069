import { type AppType } from "next/app";
import { api } from "~/utils/api";
import "~/styles/globals.css";

import "../styles/globals.css";

import { getCookie, setCookie } from "cookies-next";
import { Provider as JotaiProvider } from "jotai";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";
import { Provider } from "react-supabase";

import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

import SpecificThemeCtxProvider from "../contexts/SpecificThemeContext";
import ThemeCtxProvider from "../contexts/ThemeContext";
import UserContext from "../contexts/UserContext";

import { supabase } from "~/utils/supabase";
import { LoadingProvider } from "~/contexts/LoadingContext";
import { PlannerModeProvider } from "~/contexts/PlannerModeContext";

const MyApp: AppType = ({ Component, pageProps }) => {
  const cookieTheme = getCookie("theme") as string;
  const specificTheme = getCookie("specific-theme") as string;
  if (!cookieTheme) setCookie("theme", "classic");
  if (!specificTheme) setCookie("specific-theme", "classic");

  const theme: MantineThemeOverride = {
    colorScheme: "dark",
    colors: {
      darkRed: ["#881337"],
      darkBlue: ["#1B033F", "#213C62", "#296E85"],
      lightBlue: ["#43A6C6"],
      darkYellow: ["#facc15"],
      green: ["#36D399"],
      teal: ["#1FB2A6"],
      blueGrey: ["#56595C"],
      lightGrey: ["#A8A8A8"],
    },
    defaultRadius: "sm",
  };

  const AppShell = dynamic(() => import("../components/AppShell/index"), {
    ssr: false,
  });

  return (
    <>
      <Script
        src={
          `https://maps.googleapis.com/maps/api/js?key=` +
          process.env.NEXT_PUBLIC_GOOGLE_API_KEY +
          `&libraries=places`
        }
      />
      <Head>
        <title>Atrio</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>

      <Provider value={supabase}>
        <JotaiProvider>
          <LoadingProvider>
            <ThemeCtxProvider>
              <SpecificThemeCtxProvider>
                <MantineProvider
                  withGlobalStyles
                  withNormalizeCSS
                  withCSSVariables
                  theme={theme}
                >
                  <UserContext>
                    <NotificationsProvider>
                      <AppShell>
                        <PlannerModeProvider>
                          <Component {...pageProps} />
                        </PlannerModeProvider>
                      </AppShell>
                    </NotificationsProvider>
                  </UserContext>
                </MantineProvider>
              </SpecificThemeCtxProvider>
            </ThemeCtxProvider>
          </LoadingProvider>
        </JotaiProvider>
      </Provider>
    </>
  );
};

export default api.withTRPC(MyApp);

import React from "react";
import { Loader } from "@mantine/core";
import { useAtom, atom } from "jotai";
import PlannersChatRoom from "~/components/Misc/PlannersChatRoom";

export const plannerModeAtom = atom(false);

export const PlannerModeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [visible] = useAtom(plannerModeAtom);
  return (
    <>
      {visible && <PlannersChatRoom />}
      <div>{children}</div>
    </>
  );
};

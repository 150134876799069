import { getCookie } from "cookies-next";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

export const ThemeContext = createContext<{
  selectedTheme: string | null;
  setSelectedTheme: Dispatch<SetStateAction<string>> | null;
  fontType: string | null;
  navbarFontSize: string | null;
  userlinkFontSize: string | null;
  artistIcon: string | null;
  hostIcon: string | null;
  headerColor: string | null;
  mainColor: string | null;
  secondaryColor: string | null;
  thirdColor: string | null;
}>({
  selectedTheme: null,
  setSelectedTheme: null,
  fontType: null,
  navbarFontSize: null,
  userlinkFontSize: null,
  artistIcon: null,
  hostIcon: null,
  headerColor: null,
  mainColor: null,
  secondaryColor: null,
  thirdColor: null,
});

export default function ThemeCtxProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedTheme, setSelectedTheme] = useState<string>(
    getCookie("theme") as string,
  );
  const [fontType, setFontType] = useState("font-" + getCookie("theme"));

  const [navbarFontSize, setNavbarFontSize] = useState("");
  const [userlinkFontSize, setUserlinkFontSize] = useState("");

  const [artistIcon, setArtistIcon] = useState(
    "/artist-icons/" + getCookie("theme") + ".png",
  );
  const [hostIcon, setHostIcon] = useState(
    "/host-icons/" + getCookie("theme") + ".png",
  );

  const [headerColor, setHeaderColor] = useState<string | null>(null);
  const [mainColor, setMainColor] = useState<string | null>(null);
  const [secondaryColor, setSecondaryColor] = useState<string | null>(null);
  const [thirdColor, setThirdColor] = useState<string | null>(null);

  useEffect(() => {
    setFontType("font-" + selectedTheme);
    setArtistIcon("/artist-icons/" + selectedTheme + ".png");
    setHostIcon("/host-icons/" + selectedTheme + ".png");
    switch (selectedTheme) {
      case "classic":
        setHeaderColor("#881337");
        setMainColor("#1B033F");
        setSecondaryColor("#213C62");
        setThirdColor("#335376");
        setNavbarFontSize("text-3xl");
        setUserlinkFontSize("text-4xl");
        break;
      case "disco":
        setHeaderColor("#0A4C8F");
        setMainColor("#7267A1");
        setSecondaryColor("#514489");
        setThirdColor("#AC6AB9");
        setNavbarFontSize("text-2xl");
        setUserlinkFontSize("text-3xl");
        break;
      case "edm":
        setHeaderColor("#030609");
        setMainColor("#25184e");
        setSecondaryColor("#4D4187");
        setThirdColor("#7164BB");
        setNavbarFontSize("text-[1.3rem]");
        setUserlinkFontSize("text-[1.6rem]");
        break;
      case "jazz":
        setHeaderColor("#6c4613");
        setMainColor("#0c1e1d");
        setSecondaryColor("#48282A");
        setThirdColor("#704F4F");
        setNavbarFontSize("text-[1.4rem]");
        setUserlinkFontSize("text-[1.8rem]");
        break;
      case "rap":
        setHeaderColor("#AB9969");
        setMainColor("#262626");
        setSecondaryColor("#575757");
        setThirdColor("#858585");
        setNavbarFontSize("text-2xl");
        setUserlinkFontSize("text-3xl");
        break;
      case "rock":
        setHeaderColor("#0C0B0B ");
        setSecondaryColor("#484d48");
        setThirdColor("#636863");
        setMainColor("#2E2E5066 ");
        setNavbarFontSize("text-[.9rem]");
        setUserlinkFontSize("text-[1.3rem]");
        break;
    }
  });

  const value = {
    selectedTheme,
    setSelectedTheme,
    fontType,
    navbarFontSize,
    userlinkFontSize,
    artistIcon,
    hostIcon,
    headerColor,
    mainColor,
    secondaryColor,
    thirdColor,
  };

  return (
    <>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </>
  );
}

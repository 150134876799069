import { useState, useContext } from "react";
import {
  Paper,
  Text,
  Group,
  Collapse,
  Button,
  TextInput,
  Avatar,
} from "@mantine/core";
import { ThemeContext } from "~/contexts/ThemeContext";
import { IconChevronDown, IconChevronUp, IconHome } from "@tabler/icons";
import { ScrollArea } from "@mantine/core";

const messages: Message[] = [
  {
    id: 1,
    user: "Host",
    text: "Hello, how can I help you?",
    timestamp: new Date("2023-01-01T12:00:00"),
  },
  {
    id: 2,
    user: "Artist",
    text: "I have a question about the event.",
    timestamp: new Date("2023-01-01T12:01:00"),
  },
  {
    id: 3,
    user: "Host",
    text: "Sure, feel free to ask!",
    timestamp: new Date("2023-01-01T12:02:00"),
  },
  {
    id: 4,
    user: "Artist",
    text: "What time should I arrive for the sound check?",
    timestamp: new Date("2023-01-01T12:03:00"),
  },
  {
    id: 5,
    user: "Host",
    text: "Could you be there by 3 PM?",
    timestamp: new Date("2023-01-01T12:04:00"),
  },
  {
    id: 6,
    user: "Artist",
    text: "That works for me. Thanks!",
    timestamp: new Date("2023-01-01T12:05:00"),
  },
  {
    id: 7,
    user: "Host",
    text: "Great, see you then!",
    timestamp: new Date("2023-01-01T12:06:00"),
  },
  // ... you can add more messages as needed
];

interface Message {
  id: number;
  user: string;
  text: string;
  timestamp: Date;
}

interface MessageProps {
  user: string;
  text: string;
  timestamp: Date;
}

const Message: React.FC<MessageProps> = ({ user, text, timestamp }) => {
  return (
    <div className="flex cursor-pointer items-center rounded-sm p-1 hover:bg-slate-500 hover:text-white">
      <Avatar color="blue" radius="xl">
        {user[0]}
      </Avatar>
      <div className="ml-3 flex flex-grow justify-between">
        <div>
          <Text weight={500}>{user}</Text>
          <Text size="sm">{text}</Text>
        </div>
        <Text size="xs" color="dimmed" className="whitespace-nowrap">
          {timestamp.toLocaleTimeString()}
        </Text>
      </div>
    </div>
  );
};

export default function PlannersChatRoom() {
  const [isExpanded, setIsExpanded] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const { secondaryColor, mainColor } = useContext(ThemeContext);

  const sendMessage = () => {
    // Logic to send a message
    setNewMessage("");
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 10,
        right: 10,
        maxWidth: "500px",
        zIndex: 1000,
        borderRadius: "5px",

        display: "flex",
        flexDirection: "column",
        overflow: "hidden", // Ensure the contents do not spill out
        backgroundColor: secondaryColor as string, // Set the secondary color as the background
      }}
    >
      <div
        className="flex cursor-pointer items-center justify-between gap-2 px-3 py-2 font-semibold"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <div className="flex items-center gap-2">
          <IconHome size={22} />
          <span>Messagerie Hôte</span>
        </div>
        {isExpanded ? <IconChevronUp /> : <IconChevronDown />}
      </div>
      <Collapse in={isExpanded} style={{ flex: 1 }}>
        <ScrollArea
          style={{ height: "300px", paddingLeft: "6px", paddingRight: "6px" }}
        >
          <div
            style={{ backgroundColor: mainColor as string, padding: "10px" }}
          >
            {messages.map((message, index) => (
              <div
                key={message.id}
                style={{
                  marginBottom: index !== messages.length - 1 ? "10px" : "0",
                }}
              >
                <Message
                  user={message.user}
                  text={message.text}
                  timestamp={message.timestamp}
                />
              </div>
            ))}
          </div>
        </ScrollArea>

        <TextInput
          value={newMessage}
          onChange={(event) => setNewMessage(event.currentTarget.value)}
          placeholder="Message"
          onKeyPress={(event) => event.key === "Enter" && sendMessage()}
          className="p-1"
        />
      </Collapse>
    </div>
  );
}

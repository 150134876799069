import React from "react";
import { Loader } from "@mantine/core";
import { useAtom, atom } from "jotai";

export const loadingAtom = atom(false);

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [visible] = useAtom(loadingAtom);

  return (
    <>
      {visible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(33,60,98,0.85)]">
          <Loader size="xl" color="white" variant="bars" />
        </div>
      )}
      <div className="relative h-screen w-screen">{children}</div>
    </>
  );
};
